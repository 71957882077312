<template>
  <VitePwaManifest />
  <NuxtLoadingIndicator />
  <div
    class="flex flex-col w-full h-full justify-between pt-12 px-4 pb-8 min-h-[70vh]"
  >
    <div class="max-w-[680px] mx-auto w-full mb-12">
      <NuxtPage />
    </div>
  </div>
</template>
<script lang="ts" setup>
useHead({
  title: "QR Gateway",
  meta: [
    {
      name: "description",
      content: "Create, manage and track all your QR Codes.",
    },
  ],
});
</script>
